export default {
  methods: {
    maxValidate() {
      if (this.max) {
        if (this.inputValue.length > this.max) {
          this.message = `Не более ${this.max} символов`
          this.status = 'error'
          this.isEmptyValue = true
          return false
        } else {
          this.message = ''
          this.status = 'done'
          this.isEmptyValue = false
          return true
        }
      } else {
        this.message = ''
        this.status = 'done'
        this.isEmptyValue = true
        return true
      }
    },
  },
}
