
import mixin from '../../mixins/mixin'
import minValidator from '../../validators/minValidator'
import maxValidator from '../../validators/maxValidator'
import passwordValidator from './validators/passwordValidator'

export default {
  name: 'CoreInputTextTypesPassword',
  mixins: [
    /**
     * Набор общих данных, методов и тд.
     */
    mixin,
    /**
     * Валидатор минимального кол-ва вимволов
     */
    minValidator,
    /**
     * Валидатор максимального кол-ва вимволов
     */
    maxValidator,
    passwordValidator,
  ],

  props: {
    /**
     * Видимая подпись к полю
     */
    label: {
      type: String,
      default: 'Пароль',
    },

    /**
     * Связь с данными пользователя (если пользователь залогинен, то подставится его данные из профиля)
     */
    link: {
      type: String,
      default: null,
      validator(value) {
        return ['user', 'login'].includes(value)
      },
    },

    /**
     * Минимальное кол-во чимволов
     */
    min: {
      type: Number,
      default: null,
    },

    /**
     * Максимальное кол-во чимволов
     */
    max: {
      type: Number,
      default: null,
    },
    validated: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isShowText: false,
    }
  },
  fetch() {
    if (this.$auth.loggedIn && this.link === 'user') {
      this.inputValue = this.$auth.user.password
    }
  },

  methods: {
    validate() {
      let isValid =
        this.requiredValidate() && this.minValidate() && this.maxValidate()

      if (this.validated) {
        isValid = isValid && this.passwordValidate()
      }
      /**
       * Возвращает true если успешно пройдены все валидаторы
       */
      this.$emit('isValid', isValid)
      return isValid
    },

    /**
     * Обработчик события change
     */
    onChange() {
      this.validate()
      this.$emit('change', this.inputValue)
      this.$emit('onChange', this.inputValue)
    },
  },
}
