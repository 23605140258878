import { testPassword } from '@/project/helpers/test'
export default {
  methods: {
    passwordValidate() {
      const errors = testPassword(this.inputValue)

      const isValidUser = () => {
        return errors.filter((error) => error.status === false)
      }
      if (isValidUser().length) {
        this.message = errors
        this.status = 'error'
        return false
      } else {
        this.message = ''
        this.status = 'done'
        this.isEmptyValue = false
        return true
      }
    },
  },
}
