export default {
  methods: {
    minValidate() {
      if (this.min) {
        if (this.inputValue.length < this.min) {
          this.message = `Не менее ${this.min} символов`
          this.status = 'error'
          this.isEmptyValue = true
          return false
        } else {
          this.message = ''
          this.status = 'done'
          this.isEmptyValue = false
          return true
        }
      } else {
        this.message = ''
        this.status = 'done'
        this.isEmptyValue = true
        return true
      }
    },
  },
}
